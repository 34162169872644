import $ from 'jquery'

$('#benefit-slider').slick({
  dots: true,
  dotsClass: 'slick-dots mt-4 mx-4',
  infinite: false,
  variableWidth: false,
  speed: 300,
  arrows: false,

  responsive: [
    {
      breakpoint: 9999,
      settings: 'unslick',
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
  ],
})

$('#car-slider').slick({
  dots: true,
  dotsClass: 'slick-dots mt-4 px-4',
  variableWidth: false,
  speed: 2500,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,

  responsive: [
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 4,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
        slidesPerRow: 1,
        rows: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: 'unslick',
    },
  ],
})


$(window).resize(function() {
  $('#benefit-slider').slick('setDimensions');
  $('#car-slider').slick('setDimensions');
}); 