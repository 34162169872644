import $ from 'jquery'

$('.switch__box').click(function () {
  const html = $('html')
  let colorScheme
  if (html.hasClass('dark')) {
    colorScheme = 'light'
    html.removeClass('dark').addClass('light')
  } else if (html.hasClass('light')) {
    colorScheme = 'dark'
    html.removeClass('light').addClass('dark')
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    colorScheme = 'light'
    html.removeClass('dark').addClass('light')
  } else {
    colorScheme = 'dark'
    html.removeClass('light').addClass('dark')
  }
  const payload = new FormData()
  payload.append('colorScheme', colorScheme)
  fetch(`/prefers-color-scheme`, {
    method: 'POST',
    body: payload,
  })
})

var footerSelect = document.getElementsByClassName('select-box')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function (event) {
    this.classList.toggle('select-box--active')
  }
}

$('#menu-lang')
  .mouseover(function () {
    $('#menu-lang-box').removeClass('hidden')
  })
  .mouseleave(function () {
    $('#menu-lang-box').addClass('hidden')
  })

$('#menu-box').click(function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#header').toggleClass('header--open')
})
$('#menu-carrent')
  .mouseover(function () {
    this.classList.add('menu-carrent--open')
    $('#nav-product').removeClass('invisible opacity-0')
    $('#menu-btn-carrent').addClass('bg-light-gray')
  })
  .mouseleave(function () {
    this.classList.remove('menu-carrent--open')
    $('#nav-product').addClass('invisible opacity-0')
    $('#menu-btn-carrent').removeClass('bg-light-gray')
  })

$('#menu-servis')
  .mouseover(function () {
    this.classList.add('menu-servis--open')
    $('#nav-servis').removeClass('invisible opacity-0')
    $('#menu-btn-servis').addClass('bg-light-gray')
  })
  .mouseleave(function () {
    this.classList.remove('menu-servis--open')
    $('#nav-servis').addClass('invisible opacity-0')
    $('#menu-btn-servis').removeClass('bg-light-gray')
  })

$('.login-btn, #login-close').click(function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#login').toggleClass('hidden')
})

$('.order-btn').click(function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#order').toggleClass('hidden')
})


$('#order-close').click(function () {
  $('body').toggleClass('overflow-hidden w-full h-full')
  $('#order').toggleClass('hidden')
  $(".offer-page-1").removeClass("hidden");
  $(".offer-page-2").addClass("hidden");
  
})

$("#offer-next").click(function() {
  $(".offer-page-1").toggleClass("hidden");
  $(".offer-page-2").toggleClass("hidden");
});

$("#more-info-btn").click(function() {
  $("#more-info").addClass("hidden");
  $(".more-hidden").removeClass("hidden");
});


function toggles(name, toggleTrigger) {
  const elements = Array.from(document.querySelectorAll(`[data-${name}-id]`))
  const triggers = Array.from(document.querySelectorAll(`[data-for-${name}-id]`))
  const select = document.querySelector(`[data-select-${name}]`)

  function toggleTriggers(active) {
    triggers.forEach(toggleTrigger(active))
  }

  function toggleElements(active) {
    elements.forEach(element => {
      element.classList.toggle('hidden', element !== active)
    })
  }

  triggers.forEach(trigger => {
    trigger.addEventListener('click', event => {
      const id = event.target.getAttribute(`data-for-${name}-id`)
      toggleTriggers(event.target)
      toggleElements(document.querySelector(`[data-${name}-id="${id}"]`))
    })
  })

  if (select) {
    select.addEventListener('input', event => {
      const id = event.target.value
      toggleTriggers(document.querySelector(`[data-for-${name}-id="${id}"]`))
      toggleElements(document.querySelector(`[data-${name}-id="${id}"]`))
    })
  }
}

// homepage tabs
toggles('tab', active => tabButton => {
  if (tabButton === active) {
    tabButton.classList.add('bg-primary', 'text-white')
    tabButton.classList.remove('text-black', 'hover:text-secondary')
  } else {
    tabButton.classList.remove('bg-primary', 'text-white')
    tabButton.classList.add('text-black', 'hover:text-secondary')
  }
})

// rental categories
toggles('category', active => categoryButton => {
  if (categoryButton === active) {
    categoryButton.classList.add('bg-light-gray', 'text-secondary')
    categoryButton.classList.remove('text-gray-light')
  } else {
    categoryButton.classList.remove('bg-light-gray', 'text-secondary')
    categoryButton.classList.add('text-gray-light')
  }
})
