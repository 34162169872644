import $ from 'jquery'
import 'slick-carousel'
import './menu'
import './slider'
import './slideshow'
import './scroll-reveal.js'



window.$ = window.jQuery = $
