import $ from 'jquery'


$('.customer-logos').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 0,
  variableWidth: true,

  arrows: false,
  dots: false,
    pauseOnHover: false,
    responsive: [{
    breakpoint: 768,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 520,
    settings: {
      slidesToShow: 2
    }
  }]
});